import React, {useContext, useEffect, useRef, useState} from "react"
import {endpoint, lastUrl, productDeleteText} from "../../variables"
import {useNavigate, useLocation} from "react-router-dom"
import {Axios} from "../../Axios"
import {getUrlParams} from "../../utils/_helpers"
import Loader from "../_shared/Loader/Loader"
import Button from "../_shared/Form/Button/Button"
import {productsPath} from "../Products/Products"
import CreatableSelect from "react-select/creatable"
import Snackbar from "@mui/material/Snackbar"
import {Context} from "../../contexts/Context"
import {ProductIF} from "../../../types"
import {handleDeleteItem} from "../../shared/_item_helper"

export const productPath = "/product/"

const Product = () => {
    const [product, setProduct] = useState<ProductIF>({
        id: 0
    })
    const navigate = useNavigate()
    const urlLocation = useLocation()
    const url = getUrlParams(urlLocation.pathname, ["page", "id"])
    const productNumber = parseInt(url["id"])
    const title = product && product.id ? `Product #${product.id}` : "New product"
    const [loader, setLoader] = useState(true)
    const codeRef = useRef<HTMLInputElement>(null)
    const nameRef = useRef<HTMLInputElement>(null)
    const priceRef = useRef<HTMLInputElement>(null)
    const discountPriceRef = useRef<HTMLInputElement>(null)
    const unitRef = useRef(null)
    const commentRef = useRef<HTMLInputElement>(null)
    const units = [
        {label: "pc", value: "pc"},
        {label: "set", value: "set"},
        {label: "hour", value: "hour"},
        {label: "m", value: "m"},
        {label: "km", value: "km"}
    ]
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const {confirmation} = useContext(Context)

    useEffect(() => {
        if (!productNumber) {
            localStorage.setItem(lastUrl, `${productPath}0/`)
            setProduct({
                id: 0
            })

            setLoader(false)

            return
        }

        Axios.get(`${endpoint}${productPath}${productNumber ? `${productNumber}/` : ""}`)
            .then(({data}) => {
                const {resource} = data

                setProduct(resource)
                setLoader(false)
            })
            .catch(error => {
                console.error(`So this happened - ${error}`)
            })
    }, [productNumber])

    const updateProduct = (input?: ProductIF) => {
        const item = input || product

        Axios.put(`${endpoint}${productPath}${product.id}/`, item)
            .catch(error => {
                console.error(`So this happened - ${error}`)
            })

        setSnackbarOpen(true)
    }

    const addProduct = () => {
        // @ts-ignore
        const unit = unitRef.current?.getValue()[0] || ""

        const newProduct = {
            id: product.id,
            code: codeRef.current?.value,
            name: nameRef.current?.value,
            price: priceRef.current?.value,
            discountPrice: discountPriceRef.current?.value,
            unit: unit.value,
            comment: commentRef.current?.value,
        }

        Axios.post(`${endpoint}${productPath}`, newProduct)
            .then(({data}) => {
                const {success, insertId} = data

                if (!success) {
                    console.error(`No luck saving customer ryan, mate! [A]`)
                }

                setSnackbarOpen(true)

                navigate(`${productPath}${insertId}/`)
            })
            .catch(error => console.error(`So this happened - ${error}`))
    }

    const doSave = () => {
        if (product.id > 0) {
            updateProduct()
        } else {
            addProduct()
        }
    }

    const onChange = (input: Event | string) => {
        let newProduct

        if (product.id === 0) {
            return
        }

        if (typeof input === "string") {
            newProduct = {
                ...product,
                unit: input
            }
        } else {
            const element = input.target as HTMLInputElement

            if (!element) {
                return
            }

            newProduct = {
                ...product,
                [element.name]: element.value
            }
        }

        updateProduct(newProduct)
    }

    const deleteButton = () => {
        if (!product || !product.id) {
            return
        }

        return <Button text="Delete" additionalClassName="large tertiary"
                       onClick={(event: MouseEvent) => handleDeleteItem({
                           event,
                           confirmation,
                           id: product.id,
                           navigate,
                           text: productDeleteText,
                           resource: "product"
                       })}></Button>
    }

    return (
        loader ?
            <Loader/>
            :
            <>
                <section className="component customer">
                    <header>
                        <h1>{title}</h1>

                        <div className="actions">
                            <Button additionalClassName="primary large" text="Save" onClick={() => doSave()}></Button>
                            <Button additionalClassName="secondary large" text="Go back" onClick={() => navigate(productsPath)}></Button>
                            {deleteButton()}
                        </div>
                    </header>

                    <form className="general-form product">
                        <div className="field">
                            <label>Code</label>
                            <input
                                name="code"
                                ref={codeRef}
                                defaultValue={product?.code}
                                onChange={event => onChange(event as unknown as Event)}
                            />
                        </div>

                        <div className="field">
                            <label>Name</label>
                            <input
                                name="name"
                                ref={nameRef}
                                defaultValue={product?.name}
                                onChange={event => onChange(event as unknown as Event)}
                            />
                        </div>

                        <div className="field">
                            <label>Price</label>
                            <input
                                name="price"
                                ref={priceRef}
                                type="number"
                                defaultValue={product?.price}
                                onChange={event => onChange(event as unknown as Event)}
                            />
                        </div>

                        <div className="field">
                            <label>Discount Price</label>
                            <input
                                name="discountPrice"
                                ref={discountPriceRef}
                                type="number"
                                defaultValue={product?.discountPrice}
                                onChange={event => onChange(event as unknown as Event)}
                            />
                        </div>

                        <div className="field">
                            <label>Unit</label>
                            <CreatableSelect
                                className="select"
                                classNamePrefix="select"
                                defaultValue={units.filter(item => item.value === product.unit)[0] || units[0]}
                                name="unit"
                                id="customer-field"
                                options={units}
                                isValidNewOption={() => false}
                                ref={unitRef}
                                onChange={product => onChange(product?.value as string)}
                            />
                        </div>

                        <div className="field">
                            <label>Comment</label>
                            <input
                                name="comment"
                                ref={commentRef}
                                defaultValue={product?.comment}
                                onChange={event => onChange(event as unknown as Event)}
                            />
                        </div>

                        <input type="submit" className="hidden"/>
                    </form>

                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={1500}
                        onClose={() => setSnackbarOpen(false)}
                        message="Saved..."
                    />
                </section>
            </>
    )
}

export default Product
