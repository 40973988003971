import React, {lazy, Suspense, useEffect} from "react"
import "./assets/stylessheets/application.sass"
import {BrowserRouter, Route, Routes} from "react-router-dom"
import AuthWrapper, {tokenName} from "./contexts/Auth"
import ContextWrapper from "./contexts/Context"
import ThemeContext from "./contexts/Theme"

import {homePath} from "./components/Home/Home"
import {loginPath} from "./components/Login/Login"
import {customersPath} from "./components/Customers/Customers"
import {ordersPath} from "./components/Orders/Orders"
import {orderPath} from "./components/Order/Order"
import {Axios} from "./Axios"
import {customerPath} from "./components/Customer/Customer"
import {invoicePath} from "./components/Invoice/Invoice"
import {productsPath} from "./components/Products/Products"
import {productPath} from "./components/Product/Product"
import {profilePath} from "./components/Profile/Profile"

const PageNotFound = lazy(() => import("./components/PageNotFound/PageNotFound"))
const PrivateRoute = lazy(() => import("./PrivateRoute"))
const Home = lazy(() => import("./components/Home/Home"))
const Login = lazy(() => import("./components/Login/Login"))
const Header = lazy(() => import("./components/_shared/Header/Header"))
const Navigation = lazy(() => import("./components/_shared/Navigation/Navigation"))
const Customers = lazy(() => import("./components/Customers/Customers"))
const Customer = lazy(() => import("./components/Customer/Customer"))
const Orders = lazy(() => import("./components/Orders/Orders"))
const Order = lazy(() => import("./components/Order/Order"))
const Dialog = lazy(() => import("./components/_shared/Dialog/Dialog"))
const Invoice = lazy(() => import("./components/Invoice/Invoice"))
const Products = lazy(() => import("./components/Products/Products"))
const Product = lazy(() => import("./components/Product/Product"))
const Modal = lazy(() => import("./components/Modal/Modal"))
const Profile = lazy(() => import("./components/Profile/Profile"))

const App = () => {
    useEffect(() => {
        Axios.defaults.headers.common["Authorization"] = `Basic ${localStorage.getItem(tokenName)}`
        Axios.defaults.headers.common["ApiKey"] = process.env.REACT_APP_API_KEY || ""
    }, [])

    const isInvoice = window.location.href.includes(invoicePath)

    if (isInvoice) {
        document.getElementById("root")?.classList.add("invoice")
    }

    return (
        <BrowserRouter>
            <Suspense fallback={null}>
                <ThemeContext/>

                <ContextWrapper>
                    <AuthWrapper>
                        {!isInvoice &&
                            <>
                                <Header/>
                                <Navigation/>
                            </>
                        }

                        <Routes>
                            <Route path="/" element={<PrivateRoute/>}>
                                <Route path="/" element={<Home/>}/>
                            </Route>
                            <Route path={homePath} element={<PrivateRoute/>}>
                                <Route path={homePath} element={<Home/>}/>
                            </Route>

                            <Route path={productsPath} element={<PrivateRoute/>}>
                                <Route path={productsPath} element={<Products/>}/>
                            </Route>
                            <Route path={`${productsPath}:page/`} element={<PrivateRoute/>}>
                                <Route path={`${productsPath}:page/`} element={<Products/>}/>
                            </Route>
                            <Route path={`${productPath}:id/`} element={<PrivateRoute/>}>
                                <Route path={`${productPath}:id/`} element={<Product/>}/>
                            </Route>

                            <Route path={ordersPath} element={<PrivateRoute/>}>
                                <Route path={ordersPath} element={<Orders/>}/>
                            </Route>
                            <Route path={`${ordersPath}:page/`} element={<PrivateRoute/>}>
                                <Route path={`${ordersPath}:page/`} element={<Orders/>}/>
                            </Route>
                            <Route path={`${orderPath}:id/`} element={<PrivateRoute/>}>
                                <Route path={`${orderPath}:id/`} element={<Order/>}/>
                            </Route>
                            <Route path={`${orderPath}:id/:customer/`} element={<PrivateRoute/>}>
                                <Route path={`${orderPath}:id/:customer/`} element={<Order/>}/>
                            </Route>

                            <Route path={`${invoicePath}:id/`} element={<PrivateRoute/>}>
                                <Route path={`${invoicePath}:id/`} element={<Invoice/>}/>
                            </Route>

                            <Route path={customersPath} element={<PrivateRoute/>}>
                                <Route path={customersPath} element={<Customers/>}/>
                            </Route>
                            <Route path={`${customersPath}:page/`} element={<PrivateRoute/>}>
                                <Route path={`${customersPath}:page/`} element={<Customers/>}/>
                            </Route>
                            <Route path={`${customerPath}:id/`} element={<PrivateRoute/>}>
                                <Route path={`${customerPath}:id/`} element={<Customer/>}/>
                            </Route>

                            <Route path={`${profilePath}/`} element={<PrivateRoute/>}>
                                <Route path={`${profilePath}/`} element={<Profile/>}/>
                            </Route>

                            <Route path={loginPath} element={<Login/>}/>
                            <Route path="*" element={<PageNotFound/>}/>
                        </Routes>

                        <Dialog/>
                        <Modal/>
                    </AuthWrapper>
                </ContextWrapper>
            </Suspense>
        </BrowserRouter>
    )
}

export default App
