import {endpoint, lastUrl} from "../../variables"
import {Axios} from "../../Axios"
import {orderPath} from "../../components/Order/Order"

interface GetOrderIF {
    orderNumber: number
    orderCallback: Function
    itemsCallback: Function
    path?: string
    customer?: number
    loaderCallback?: Function
}

const itemPath = "/items/"

export const getOrder = ({orderNumber, orderCallback, itemsCallback, path, customer, loaderCallback}: GetOrderIF) => {
    if (!orderNumber) {
        localStorage.setItem(lastUrl, `${orderPath}0/`)
        loaderCallback && loaderCallback(false)
        orderCallback({
            id: 0,
            customerId: customer
        })

        return
    }

    Axios.get(`${endpoint}${orderPath}${orderNumber}/`)
        .then(({data}) => {
            // getting order info
            const {resource} = data

            if (!resource) {
                console.error(`No success on getting items`)

                return
            }

            if (path) {
                localStorage.setItem(lastUrl, `${path}${resource.id}/`)
            }

            orderCallback({
                ...resource,
                id: orderNumber
            })
        })
        .then(() => {
            Axios.get(`${endpoint}${itemPath}${orderNumber}/`)
                .then(({data}) => {
                    // getting order ITEMS info
                    const {resource} = data

                    if (!resource) {
                        console.error(`No success on getting items`)

                        return
                    }

                    itemsCallback(resource)
                })
                .catch(error => console.error(`So this happened - ${error}`))
        })
        .finally(() => loaderCallback && loaderCallback(false))
        .catch(error => console.error(`So this happened - ${error}`))
}
