import {Md5} from "ts-md5"

export const handlePasswordSend = (password: string) => {
    const splitterPoint = Math.floor(password.length / 2)
    const a = password.substring(0, splitterPoint)
    const b = password.substring(splitterPoint)
    const reversedA = a.split("").reverse().join("")
    const reversedB = b.split("").reverse().join("")
    const passwordAB = reversedA + reversedB

    return Md5.hashStr(passwordAB)
}
