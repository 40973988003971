import {endpoint} from "../../variables"
import {Axios} from "../../Axios"
import {customerPath} from "../../components/Customer/Customer"
import {customersPath} from "../../components/Customers/Customers"
import {CustomerIF, DeleteItemIF, OrderIF, ProductIF} from "../../../types"
import {productPath} from "../../components/Product/Product"
import {orderPath} from "../../components/Order/Order"

export const deleteItem = ({confirmation, id, list, setList, navigate, resource}: DeleteItemIF) => {
    confirmation.update({
        show: false
    })
    const mapper = {
        customer: customerPath,
        product: productPath,
        order: orderPath
    }

    Axios.delete(`${endpoint}${mapper[resource]}${id}/`)
        .then(({data}) => {
            if (!data.success) {
                console.error(`Failed to delete ${resource}`)

                return
            }

            if (list) {
                if (!setList) {
                    return
                }

                setList((list as Array<ProductIF | OrderIF | CustomerIF>).filter((item: ProductIF | OrderIF | CustomerIF) => item.id !== id))
            } else {
                navigate(customersPath)
            }
        })
        .catch(error => console.error(`So this happened - ${error}`))
}
