import React, {lazy} from "react"
import {storageChange, themeString} from "../variables"

const Flat = lazy(() => import("../themes/Flat/Flat"))
const Colors = lazy(() => import("../themes/Colors/Colors"))

const Themes = {
    flat: Flat,
    color: Colors
}

const ThemeContext = () => {
    const defaultTheme = "flat"
    const theme = localStorage.getItem(themeString) || defaultTheme
    const Content = Themes[theme]

    window.addEventListener(storageChange, () => {
        window.location.reload()
    })

    return (
        <Content/>
    )
}

export default ThemeContext
