import React, {forwardRef, RefObject, useRef} from "react"
import "./Button.sass"

interface ButtonIF {
    additionalClassName: string
    text?: string
    Element?: () => JSX.Element
    onClick?: Function
}

const Button = forwardRef(({additionalClassName, text, Element, onClick}: ButtonIF, ref) => {
    const buttonRef = useRef(null)

    if (!text && !Element) {
        return null
    }

    const className = [
        "button",
        ...(additionalClassName ? [additionalClassName] : []),
        ...(Element ? ["icon"] : [])
    ].join(" ")

    const params = {
        className,
        ref: ref ? ref as RefObject<HTMLButtonElement> : buttonRef,
        ...(onClick ? {onClick: (event: any) => onClick(event)} : null)
    }

    return (
        <button {...params}>
            {text && <span>{text}</span>}
            {Element && <Element/>}
        </button>
    )
})

export default Button
