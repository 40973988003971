import {deleteItem} from "../utils/Delete/deleteItem"
import {ConfirmationIF, CustomerIF, OrderIF, ProductIF} from "../../types"
import {NavigateFunction} from "react-router-dom"

interface HandleDeleteItemIF {
    event: Event
    confirmation: ConfirmationIF
    id: number
    list?: ProductIF[] | OrderIF[] | CustomerIF[]
    setList?: Function
    navigate?: NavigateFunction
    text: string
    resource: string
}

export const handleDeleteItem = ({event, confirmation, id, list, setList, navigate, text, resource}: HandleDeleteItemIF) => {
    event.stopPropagation()

    confirmation.update({
        ...confirmation,
        show: true,
        text,
        confirm: () => deleteItem({
            confirmation,
            id,
            list,
            setList,
            navigate,
            resource
        })
    })
}
