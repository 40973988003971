import {endpoint, lastUrl} from "../../variables"
import {Axios} from "../../Axios"

import {customerPath} from "../../components/Customer/Customer"

interface GetCustomerIF {
    customerNumber: number
    callback: Function
}

export const getCustomer = ({customerNumber, callback}: GetCustomerIF) => {
    Axios.get(`${endpoint}${customerPath}${customerNumber}/`)
        .then(({data}) => {
            const {resource} = data

            if (!resource) {
                console.error(`No success on getting customer`)

                return
            }

            localStorage.setItem(lastUrl, `${customerPath}${resource.id}/`)
            callback(resource)
        })
        .catch(error => console.error(`So this happened - ${error}`))
}
