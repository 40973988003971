import React, {lazy, ReactNode, useEffect, useRef, useState} from "react"
import {customerPath} from "../../Customer/Customer"
import Loader from "../Loader/Loader"
import Pagination from "@mui/material/Pagination"
import {capitalizeFirst} from "../../../utils/_helpers"
import {useNavigate} from "react-router-dom"
import {customersPath} from "../../Customers/Customers"
import {orderPath} from "../../Order/Order"
import {ordersPath} from "../../Orders/Orders"
import "../../../assets/stylessheets/shared/pagination.sass"
import Search from "../Icons/Search"
import "./DefaultList.sass"
import {endpoint} from "../../../variables"
import {Axios} from "../../../Axios"
import {productsPath} from "../../Products/Products"
import {productPath} from "../../Product/Product"
import {CustomerIF, MetaIF, OrderIF} from "../../../../types"

const Button = lazy(() => import("../Form/Button/Button"))

interface ModalIF {
    header?: string
    cssClass?: string
    body?: ReactNode
    footer?: string | ReactNode,
    closeButton?: boolean
}

interface DefaultListIF {
    list: string
    loader: boolean
    setLoader: Function
    renderer: string | JSX.Element
    meta: null | MetaIF
    page: number
    callback: Function
    id?: number
    addAction?: ModalIF | Function
    compact?: boolean
}

const DefaultList = ({list, loader, setLoader, renderer, meta, page, callback, id, compact}: DefaultListIF) => {
    const navigate = useNavigate()
    let path: string = ""
    let paths: string = ""
    const [showSearch, setShowSearch] = useState(false)
    const searchField = useRef(null)
    const [keyword, setKeyword] = useState<string | null>(null)

    switch (list) {
        case "customers":
            path = customerPath
            paths = customersPath

            break
        case "orders":
            path = orderPath
            paths = ordersPath

            break
        case "products":
            path = productPath
            paths = productsPath

            break
        default:
            break
    }

    useEffect(() => {
        if (!keyword) {
            return
        }

        Axios.get(`${endpoint}/search${paths}${encodeURIComponent(keyword)}/${page}/`)
            .then(({data}) => {
                callback(data as Array<OrderIF> | Array<CustomerIF>)
            })
            .catch(error => {
                console.error(`So this happened - ${error}`)
            })
    }, [keyword])

    const handleEscape = (event: KeyboardEvent) => {
        if (event.key !== "Escape" || !searchField.current) {
            return
        }

        if (keyword) {
            setKeyword("")
            callback(false)
        } else {
            setShowSearch(false)
        }
    }

    const showSearchField = () => {
        if (!showSearch) {
            return ""
        }

        return (
            <input autoFocus placeholder="Type at least 3 letters..."
                   className="search"
                   value={keyword || ""}
                   onChange={event => setKeyword(event.currentTarget.value)}
                   onKeyUp={event => handleEscape(event as unknown as KeyboardEvent)}
                   ref={searchField}
            />
        )
    }

    return (
        <section className={`component ${list}`}>
            <header>
                <h1>{compact ? "Latest " : ""}{capitalizeFirst(list)}</h1>

                {!compact &&
                <div className="actions">
                    <div className="search-wrapper">
                        {showSearchField()}
                        <Button Element={Search}
                                additionalClassName="large primary"
                                onClick={() => setShowSearch(true)}
                        />
                    </div>
                    <Button additionalClassName="large primary"
                            text="Add"
                            onClick={() => navigate(`${path}0/${id ? `${id}/` : ''}`)}
                    />
                </div>}
            </header>

            {loader ?
                <Loader additionalClass="relative"/>
                :
                <>
                    {renderer}

                    {meta && <Pagination count={meta.total}
                                         page={page}
                                         color="primary"
                                         onChange={(_event, value) => {
                                             setLoader(true)
                                             navigate(`${paths}${value}/`)
                                         }}/>}
                </>
            }
        </section>
    )
}

export default DefaultList
