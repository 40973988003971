import React, {createContext, useEffect, useState, ReactNode} from "react"
import {Axios} from "../Axios"
import {isObjectEmpty} from "../utils/_helpers"
import {endpoint, themeString} from "../variables"
import {loginPath} from "../components/Login/Login"
import {useNavigate} from "react-router-dom"
import {UserIF, UserResponseIF} from "../../types"

interface AuthContextIF {
    rootState: any
    logoutUser: Function
    loginUser: Function
    isLoggedIn: Function,
    setTheUser: Function
}

interface AuthWrapperIF {
    children: ReactNode
}

export const AuthContext = createContext<AuthContextIF>({
    rootState: {},
    logoutUser: () => {},
    loginUser: () => {},
    isLoggedIn: () => {},
    setTheUser: () => {}
})

export const tokenName = "loginToken"

const AuthWrapper = ({children}: AuthWrapperIF) => {
    const [userData, setUserData] = useState<UserResponseIF | null>(null)
    const navigate = useNavigate()
    const [isAuth, setIsAuth] = useState(false)
    const [theUser, setTheUser] = useState<UserResponseIF | null>(null)

    // On Click the Logout button
    const logoutUser = () => {
        Axios.delete(`${endpoint}/user/`)
            .then(() => {
                localStorage.removeItem(tokenName)

                setUserData(null)
                setIsAuth(false)

                navigate(loginPath)
            })
            .catch(error => console.error(`Oh dear, we messed it up - ${error}`))
    }

    // Sending the user Login request
    const loginUser = async (user: UserIF) => {
        const {username, password} = user
        const defaultError = "Crash'n'burn, no luck, mate: "

        // no username/password provided
        if (!username || !password) {
            console.error(`Something happened and we are sorry (no we're not) v1`)

            return false
        }

        // setting the login in DB
        return await Axios.post(`${endpoint}/login/`,
            {
                username: username
            })
            .then(async ({data}) => {
                const {success, message} = data

                if (!success) {
                    console.error(defaultError, message)

                    return {
                        success: false,
                        error: message
                    }
                }

                await Axios.get(`${endpoint}/user/`)
                    .then(resource => {
                        const {success, data} = resource.data

                        if (!success || isObjectEmpty(data)) {
                            const message = "User got lost in the swamp"

                            console.error(defaultError, message)

                            return {
                                success: false,
                                error: message
                            }
                        }

                        setIsAuth(true)
                        setUserData(data)
                    })
            })
            .catch(error => {
                console.error(`So this happened while logging in - ${error}`)

                return {
                    success: false,
                    error: error
                }
            })
    }

    useEffect(() => {
        if (userData) {
            setIsAuth(true)
            setTheUser(userData)
        }
    }, [userData])

    useEffect(() => {
        if (!theUser ||  !theUser.data) {
            return
        }

        localStorage.setItem(themeString, theUser.data.theme || "")
    }, [theUser])

    const isLoggedIn = async () => {
        return Axios.get(`${endpoint}/user/`)
            .then(({data}) => {
                const {success, data: userData} = data

                if (success) {
                    setIsAuth(true)
                    setTheUser(userData)
                }

                return data
            })
            .catch(error => {
                console.error(`We die tonight - ${error}`)

                return {
                    server: "error"
                }
            })
    }

    const contextValue = {
        rootState: {isAuth, theUser},
        isLoggedIn: () => isLoggedIn(),
        loginUser: (user: UserIF) => loginUser(user),
        logoutUser: () => logoutUser(),
        setTheUser: setTheUser
    }

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthWrapper
