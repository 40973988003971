import React from "react"
import "./NoEntries.sass"

const NoEntries = () => {
    return (
        <div className="no-entries">Looks like there are no entries...</div>
    )
}

export default NoEntries
