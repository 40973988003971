import {useEffect, RefObject} from "react"

export const useOutsideAlerter = (ref: RefObject<HTMLDivElement>, callback: Function) => {
    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            if (ref && ref.current && !ref.current.contains(event.target as Node)) {
                callback()
            }
        }

        document.addEventListener("mousedown", handleClickOutside)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [ref, callback])
}
