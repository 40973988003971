import {endpoint} from "../../variables"
import {Axios} from "../../Axios"
import {productsPath} from "../../components/Products/Products"
import {GetterIF} from "../../../types"

export const getProducts = ({callback, page}: GetterIF) => {
    Axios.get(`${endpoint}${productsPath}${page ? `${page}/` : ""}`)
        .then(({data}) => {
            callback(data)
        })
        .catch(error => {
            console.error(`So this happened - ${error}`)
        })
}
