import {SelectOptionIF, UserResponseIF} from "../../types"

export const isObjectEmpty = (obj: UserResponseIF | null) => {
    return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype
}

export const getUrlParams = (url: string, mapper: any) => {
    const split = url.split("/").filter(Boolean)
    const mapped: Array<string> = []

    if (mapper) {
        mapper.map((key: string, index: number) => {
            mapped[key] = split[index]
        })

        return mapped
    }

    return split
}

export const capitalizeFirst = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export const createOptionsList = (data: any, value?: string) => {
    const array: Array<SelectOptionIF> = []

    data.forEach((item: any) => array.push({
        label: item["name"] || "",
        value: item[value ? value : "id"]
    }))

    return array
}
