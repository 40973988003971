import React from "react"
import {ReactComponent as Logo} from "../../../assets/images/esc.svg"
import "./Loader.sass"

interface LoaderIF {
    additionalClass?: string
}

const Loader = ({additionalClass}: LoaderIF) => {
    return (
        <div className={`loader${additionalClass ? ` ${additionalClass}` : ''}`}>

            <Logo/>
            <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>

        </div>

    )
}

export default Loader
