import React, {useState, useEffect, lazy, useRef, useContext, ChangeEvent} from "react"
import {defaultDiscountShipping, defaultShipping, endpoint, orderDeleteText, vat} from "../../variables"
import {useNavigate} from "react-router-dom"
import OrderFooter from "./OrderFooter"
import {useLocation} from "react-router"
import {createOptionsList, getUrlParams} from "../../utils/_helpers"
import {Axios} from "../../Axios"
import "./Order.sass"
import {ordersPath} from "../Orders/Orders"
import {formatPrice} from "../../shared/_order_helper"
import {tokenName} from "../../contexts/Auth"
import {Context} from "../../contexts/Context"
import {invoicePath} from "../Invoice/Invoice"
import {getOrder} from "../../utils/Getters/getOrder"
import {AxiosRequestConfig} from "axios"
import "../_shared/Form/Form.sass"
import "../_shared/GeneralTable/GeneralTable.sass"
import Item from "./Item"
import Button from "../_shared/Form/Button/Button"
import Loader from "../_shared/Loader/Loader"
import {getProducts} from "../../utils/Getters/getProducts"
import {ItemIF, OrderIF, ProductIF, SelectOptionIF} from "../../../types"
import {handleDeleteItem} from "../../shared/_item_helper"

const OrderHead = lazy(() => import("./OrderHead"))

interface ParamsIF {
    token: string | null
    totalPrice?: string
    totalDiscountPrice?: string
}

export const orderPath = "/order/"

const Order = () => {
    const urlLocation = useLocation()
    const url = getUrlParams(urlLocation.pathname, ["page", "id", "customer"])
    const orderNumber = parseInt(url["id"])
    const customer = parseInt(url["customer"])
    const [order, setOrder] = useState<OrderIF | null>(null)
    const [items, setItems] = useState<Array<ItemIF>>([])
    const navigate = useNavigate()
    const customerRef = useRef<HTMLInputElement>(null)
    const dateRef = useRef<HTMLInputElement>(null)
    const [params, setParams] = useState<ParamsIF>({
        token: localStorage.getItem(tokenName),
        totalPrice: "",
        totalDiscountPrice: ""
    })
    const [allowAutoSave, setAllowAutoSave] = useState(false)
    const mapper = {
        "customer-discount": "customerDiscount",
        "discount-shipping": "discountShipping",
        "discount-price": "discountPrice"
    }
    const {menu, confirmation} = useContext(Context)
    const [loader, setLoader] = useState(true)
    const [shortProducts, setShortProducts] = useState<Array<SelectOptionIF>>([])
    const [products, setProducts] = useState<Array<ProductIF>>([])

    useEffect(() => {
        menu?.update(null)

        getProducts({
            callback: ({resource}: any) => {
                setProducts(resource)
                setShortProducts(createOptionsList(resource, "code"))
            }
        })
    }, [menu])

    // load ORDER from DB
    useEffect(() => {
        getOrder({
            orderNumber,
            orderCallback: setOrder,
            itemsCallback: setItems,
            loaderCallback: setLoader,
            path: orderPath,
            customer
        })
    }, [orderNumber, customer])

    useEffect(() => {
        calculateOrder()
    }, [items])

    const getTotals = () => {
        let total = 0
        let discount_total = 0

        Object.values(items).map((item: ItemIF) => {
            const quantity = item.quantity || 0
            total = total + item.price * quantity
            discount_total = discount_total + item.discountPrice * quantity

            return true
        })

        return {
            totalPrice: total.toFixed(2),
            totalDiscountPrice: discount_total.toFixed(2)
        }
    }

    const getShipping = () => {
        if (!order) {
            return {
                shipping: "",
                discountShipping: ""
            }
        }

        let shipping, discountShipping

        shipping = order.shipping || defaultShipping
        discountShipping = order.discountShipping || defaultDiscountShipping

        return {
            shipping: parseFloat(String(shipping)).toFixed(2),
            discountShipping: parseFloat(String(discountShipping)).toFixed(2)
        }
    }

    const calculateOrder = () => {
        if (!order) {
            return
        }

        if (items.length) {
            const totals = getTotals()
            const shipping = getShipping()

            const customerDiscount = order.customerDiscount || 0
            const discountPrice = (parseFloat(totals.totalPrice) * (100 - customerDiscount) / 100).toFixed(2)
            const discountPriceWShipping = (parseFloat(discountPrice) + parseFloat(shipping.shipping)).toFixed(2)
            const price = (parseFloat(totals.totalPrice) + parseFloat(shipping.shipping)).toFixed(2)
            const offer = order.offer || parseFloat((Math.floor(parseFloat(discountPriceWShipping) / 5) * 5).toFixed(2))
            const vatPrice = (parseFloat(String(offer)) / (1 + vat)).toFixed(2)
            const customerWin = (parseFloat(price) - parseFloat(String(offer))).toFixed(2)
            const myWin = (parseFloat(vatPrice) - parseFloat(totals.totalDiscountPrice) - parseFloat(shipping.discountShipping)).toFixed(2)

            setOrder({
                ...order,
                ...totals,
                ...shipping,
                price,
                discountPriceWShipping,
                customerDiscount,
                discountPrice,
                vatPrice,
                offer,
                customerWin,
                myWin
            })
        }

        if (allowAutoSave) {
            setAllowAutoSave(false)

            doSave()
        }
    }

    useEffect(() => {
        if (!Object.entries(items).length) {
            return
        }

        calculateOrder()
    }, [])

    const addItem = async () => {
        try {
            const {data} = await Axios.post(`${endpoint}/item/${orderNumber}/`)
            const newItem = {
                code: "",
                comment: "",
                name: "",
                discountPrice: 0,
                price: 0,
                quantity: 0,
                id: parseInt(data.insertId),
                order_id: orderNumber
            }

            setItems([
                ...items,
                newItem
            ])
        } catch (error) {
            return console.error(`So this happened while logging in - ${error}`)
        }
    }

    const saveItem = (item: ItemIF) => {
        const newParams = {
            ...params,
            ...item
        }

        setParams(newParams)

        delete newParams.totalPrice
        delete newParams.totalDiscountPrice

        Axios.put(`${endpoint}/item/${item.id}/`, newParams)
            .catch(error => {
                console.error(`So this happened - ${error}`)
            })
    }

    const updateItem = (index: number, input: any) => {
        const priceFields = ["quantity", "price", "shipping", "discount-price", "customer-discount"]
        let currentItem = {} as ItemIF

        const newItems = items.map((item, i) => {
            const quantity = item.quantity || 0

            if (index === i) {
                if (input.target) {
                    const {name, value} = input.target

                    if (priceFields.includes(name)) {
                        const key = mapper[name] || name
                        item[key] = formatPrice(value)

                        if (isNaN(item[key])) {
                            item[key] = 0
                        }

                        item.totalPrice = formatPrice(quantity * item.price)
                        item.totalDiscountPrice = formatPrice(quantity * item.discountPrice)
                    } else {
                        item[name] = value
                    }
                } else {
                    const addedProduct = products.filter(item => item.code === input.value)[0]
                    const price = addedProduct.price || 0
                    const discountPrice = addedProduct.discountPrice || 0

                    item = {
                        ...item,
                        code: addedProduct.code || "",
                        comment: addedProduct.comment || "",
                        name: addedProduct.name || "",
                        price: price,
                        discountPrice: discountPrice,
                        totalPrice: formatPrice(quantity * price),
                        totalDiscountPrice: formatPrice(quantity * discountPrice)
                    }
                }

                currentItem = item
            }

            return item
        })

        setItems(newItems)
        saveItem(currentItem)
        calculateOrder()
    }

    const handleRemoveItem = (id: number) => {
        confirmation.update({
            ...confirmation,
            show: true,
            confirm: () => removeItem(id)
        })
    }

    const removeItem = (id: number) => {
        confirmation.update({
            show: false
        })

        Axios.delete(`${endpoint}/item/${id}/`, params as AxiosRequestConfig)
            .then(({data}) => {
                if (!data.success) {
                    console.error(`Failed to delete item`)
                }

                setItems(items.filter(item => item.id !== id))
            })
            .catch(error => console.error(`So this happened - ${error}`))
    }

    const totalChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!order) {
            return
        }

        const {name, value} = event.target
        const field = mapper[name] || name

        setAllowAutoSave(true)
        setOrder({
            ...order,
            [field]: value
        })
    }

    useEffect(() => {
        if (!order) {
            return
        }

        calculateOrder()
    }, [order?.customerDiscount, order?.shipping, order?.discountShipping, order?.offer])

    const noItems = () => {
        return (
            <tr className="no-items">
                <td colSpan={100}>No items yet. <span onClick={() => addItem()}>Add</span> first one</td>
            </tr>
        )
    }

    const title = order && order.id ? `Order #${order.id}` : "New order"

    const update = (input: OrderIF) => {
        setOrder({
            ...order,
            ...input
        })

        doSave(input)
    }

    const doSave = (input?: OrderIF) => {
        if (!order) {
            return
        }

        let timestamp = input?.timestamp || order.timestamp
        const customerId = input?.customerId || order.customerId

        if (timestamp?.includes(" ")) {
            timestamp = `${timestamp?.replace(" ", "T")}.000Z`
        }

        if (order.id > 0) {
            // update existing ORDER
            Axios.put(`${endpoint}${orderPath}${order.id}/`, {...order, customerId, timestamp})
                .catch(error => {
                    console.error(`So this happened - ${error}`)
                })
        } else {
            // add new ORDER
            Axios.post(`${endpoint}${orderPath}`,
                {
                    customerId: customerId ? customerId : 0,
                    timestamp
                })
                .then(({data}) => {
                    const {success, insertId} = data

                    if (!success || !insertId) {
                        console.error(`No luck saving private ryan, mate!`)
                    }

                    if (insertId) {
                        navigate(`${orderPath}${insertId}/`)
                    }
                })
                .catch(error => console.error(`So this happened - ${error}`))
        }
    }

    const invoiceButton = () => {
        if (!order || !order.id) {
            return
        }

        return <Button text="Invoice" additionalClassName="large primary"
                       onClick={() => window.open(`${invoicePath}${order.id}/`, "_blank")}/>
    }

    const deleteButton = () => {
        if (!order || !order.id) {
            return
        }

        return <Button text="Delete" additionalClassName="large tertiary"
                       onClick={(event: MouseEvent) => handleDeleteItem({
                           event,
                           confirmation,
                           id: order.id,
                           navigate,
                           text: orderDeleteText,
                           resource: "order"
                       })}></Button>
    }

    if (!order) {
        return null
    }

    return (
        <section className="component order">
            <header>
                <h1>{title}</h1>

                <div className="actions">
                    {invoiceButton()}
                    <Button text="Save" additionalClassName="large primary" onClick={() => doSave()}/>
                    <Button text="Go back" additionalClassName="large secondary" onClick={() => navigate(ordersPath)}/>
                    {deleteButton()}
                </div>
            </header>

            {loader ?
                <Loader additionalClass="relative"/>
                :
                <div>
                    <OrderHead order={order} customerRef={customerRef} dateRef={dateRef} update={update}/>

                    <table className="general-form general-table order-table">
                        <thead>
                        <tr>
                            <th className="id">#</th>
                            <th className="name">Name</th>
                            <th className="quantity">Qty</th>
                            <th className="price">Price</th>
                            <th className="total-price">Total</th>
                            <th className="discount-price">Deal</th>
                            <th className="total-discount">Total</th>
                            <th className="discount">%</th>
                            {Object.entries(items).length ? <th className="action"></th> : ""}
                        </tr>
                        </thead>

                        <tbody>
                        {Object.entries(items).length ?
                            Object.values(items).map((item, index) => {
                                return <Item item={item}
                                             index={index}
                                             updateItem={updateItem}
                                             removeItem={handleRemoveItem}
                                             products={shortProducts}
                                             key={`${item}~${index}`}/>
                            })
                            :
                            noItems()
                        }

                        </tbody>
                        <OrderFooter order={order} totalChange={totalChange} addItem={addItem}/>
                    </table>
                </div>
            }
        </section>
    )
}

export default Order
