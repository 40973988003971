export const isEmpty = (obj: object | null) => {
    if (obj === null) {
        return false
    }

    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            return false
        }
    }

    return true
}

export const formatDate = (timestamp?: string, extra?: number) => {
    if (!timestamp) {
        return
    }

    const date = new Date(timestamp || "")

    if (extra) {
        date.setDate(date.getDate() + extra)
    }

    return date.toISOString().split("T")[0].split("-").reverse().join(".")
}
