import React, {useContext, useEffect, useState} from "react"
import {useForm} from "react-hook-form"
import {useNavigate} from "react-router-dom"
import {ErrorMessage} from "@hookform/error-message"
import {AuthContext, tokenName} from "../../contexts/Auth"
import {homePath} from "../Home/Home"
import Button from "../_shared/Form/Button/Button"
import {handlePasswordSend} from "../../utils/_auth"
import {Axios} from "../../Axios"
import Loader from "../_shared/Loader/Loader"
import "../_shared/Form/Error.sass"
import {Context} from "../../contexts/Context"

export const loginPath = "/login"

interface LoginCredentialsIF {
    username: string
    password: string
}

export const setAuthentication = ({username, password}: LoginCredentialsIF) => {
    const hashPassword = handlePasswordSend(password)
    const encode = window.btoa(`${username}:${hashPassword}`)

    localStorage.setItem(tokenName, encode)
    Axios.defaults.headers.common["Authorization"] = `Basic ${encode}`
}

const Login = () => {
    const {modal} = useContext(Context)
    const {register, formState: {errors}, handleSubmit} = useForm<LoginCredentialsIF>()
    const {loginUser} = useContext(AuthContext)
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const header = "Login"

    const doSubmit = async (values: LoginCredentialsIF) => {
        setLoader(true)
        setAuthentication(values)
        loginUser(values)
            .then(() => {
                setLoader(false)

                modal.update({
                    ...modal,
                    show: false
                })

                navigate(homePath)
            })
    }

    const onSubmit = handleSubmit((data) => doSubmit(data))

    const Body = () => {
        return (
            <div onSubmit={onSubmit} className="general-form white">
                <div className="field">
                    <label>Username</label>
                    <input {...register(
                        "username",
                        {
                            required: "This very needed",
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: "You sure this is email?"
                            }
                        })}
                    />
                    <ErrorMessage errors={errors} name="username"
                                  render={({message}) => <div className="error">{message}</div>}/>
                </div>
                <div className="field">
                    <label>Password</label>
                    <input type="password" {...register(
                        "password",
                        {
                            required: "This very needed"
                        })}
                    />
                </div>
                <ErrorMessage errors={errors} name="password"
                              render={({message}) => <div className="error">{message}</div>}/>
                <input type="submit" className="hidden"/>
            </div>
        )
    }
    const Footer = () => <Button text="¡Vamos!" additionalClassName="large primary" onClick={onSubmit}/>

    useEffect(() => {
        modal.update({
            ...modal,
            show: true,
            header,
            body: Body(),
            footer: Footer()
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return loader ? <Loader/> : null
}

export default Login
