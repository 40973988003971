import React from "react"
import {getTotal} from "../../shared/_order_helper"
import {OrderIF} from "../../../types"
import Button from "../_shared/Form/Button/Button"

interface OrderFooterIF {
    order: OrderIF
    totalChange: Function
    addItem: Function
}

const OrderFooter = ({order, totalChange, addItem}: OrderFooterIF) => {
    const addItemButton = () => {
        return (
            <tr>
                <td className="add-button" colSpan={100}>
                    <Button text="Add" additionalClassName="button primary" onClick={() => addItem()}/>
                </td>
            </tr>
        )
    }

    return (
        <tfoot>

        {addItemButton()}

        <tr>
            <td colSpan={2} className="title">Total</td>
            <td className="fake-input">
                {order.totalPrice}
            </td>
            <td className="fake-input">
                {order.totalDiscountPrice}
            </td>
            <td colSpan={5}/>
        </tr>
        <tr>
            <td colSpan={2} className="title">
                Discount
                <input name="customer-discount"
                       type="number"
                       step="0.01"
                       defaultValue={order.customerDiscount}
                       onChange={event => totalChange(event)}/>
                %
            </td>
            <td className="fake-input">
                {order.discountPrice}
            </td>
            <td colSpan={6}/>
        </tr>
        <tr>
            <td colSpan={2} className="title">Shipping:</td>
            <td>
                <input name="shipping"
                       type="number"
                       step="0.01"
                       defaultValue={order.shipping}
                       onChange={event => totalChange(event)}/>
            </td>
            <td>
                <input name="discount-shipping"
                       type="number"
                       step="0.01"
                       defaultValue={order.discountShipping}
                       onChange={event => totalChange(event)}/>
            </td>
            <td colSpan={5}/>
        </tr>
        <tr>
            <td colSpan={2} className="title">Total with shipping:</td>
            <td>
                <div className="fake-input">
                    {order.price}
                </div>
            </td>
            <td colSpan={5}/>
        </tr>
        <tr>
            <td colSpan={2} className="title">Total with shipping after discount:</td>
            <td>
                <div className="fake-input">
                    {order.discountPriceWShipping}
                </div>
            </td>
            <td>
                <div className="fake-input">
                    {getTotal(order)}
                </div>
            </td>
            <td colSpan={6}/>
        </tr>
        <tr>
            <td colSpan={2} className="title">w/o VAT</td>
            <td>
                <div className="fake-input">
                    {order.vatPrice}
                </div>
            </td>
            <td colSpan={6}/>
        </tr>
        <tr>
            <td colSpan={2} className="title">Offer:</td>
            <td>
                <input name="offer"
                       type="number"
                       step="0.01"
                       defaultValue={order.offer}
                       onChange={event => totalChange(event)}/>
                <div/>
            </td>
            <td colSpan={6}/>
        </tr>
        <tr>
            <td colSpan={2} className="title">Win / gain:</td>
            <td>
                <div className="win">
                    {order.customerWin}
                </div>
            </td>
            <td>
                <div className="win">
                    {order.myWin}
                </div>
            </td>
            <td colSpan={5}/>
        </tr>
        </tfoot>
    )
}

export default OrderFooter
