import React from "react"

interface IconIF {
    callback: Function
}

const View = ({callback}: IconIF) => {
    return (
        <svg viewBox="0 0 1024 1024" onClick={() => callback()}>
            <path
                d="M513.92 446.506667a64 64 0 1 1-64 64 64 64 0 0 1 64-64m0-64a128 128 0 1 0 128 128 128 128 0 0 0-128-128z"/>
            <path
                d="M512 289.706667c128 0 257.706667 67.84 397.226667 207.146666a21.333333 21.333333 0 0 1 0 30.08C770.133333 666.453333 640 734.293333 512 734.293333s-257.28-67.84-396.8-207.146666a21.333333 21.333333 0 0 1 0-30.08c139.52-139.52 268.8-207.36 396.8-207.36m0-64c-145.92 0-291.84 75.306667-442.453333 225.92a85.333333 85.333333 0 0 0 0 120.746666C220.586667 722.986667 366.506667 798.293333 512 798.293333s292.266667-75.306667 442.666667-225.92a85.333333 85.333333 0 0 0 0-120.746666C804.266667 301.013333 658.346667 225.706667 512 225.706667z"/>
        </svg>
    )
}

export default View
