const development = "development"

export const shop = {
    paruzzi: "paruzzi",
    heritage: "heritage"
}

// const prod_endpoint = `${document.location.origin}/${process.env.REACT_APP_API}`
const productionEndpoint = "https://api.eastsidecustoms.com"
const developmentEndpoint = "http://localhost:3999"

export const isDevelopment = () => {
    return process.env.NODE_ENV === development
}

export const endpoint = isDevelopment() ? developmentEndpoint : productionEndpoint

export const lastUrl = "lastUrl"
export const defaultShipping = "19.95"
export const defaultDiscountShipping = "16.49"
export const vat = .22
export const themeString = "theme"
export const storageChange = "storageChange"

export const customerDeleteText = "Are you sure you want to delete this customer?<br/>All orders under this customer will be deleted!"
export const productDeleteText = "Are you sure you want to delete this product?"
export const orderDeleteText = "Are you sure you want to delete this order?"
