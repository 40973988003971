import React, {lazy, useContext, useEffect, useState} from "react"
import {customerDeleteText, endpoint, lastUrl} from "../../variables"
import {useNavigate, useLocation} from "react-router-dom"
import {customersPath} from "../Customers/Customers"
import {Axios} from "../../Axios"
import {getUrlParams} from "../../utils/_helpers"
import "./Customer.sass"
import Orders from "../Orders/Orders"
import {getCustomer} from "../../utils/Getters/getCustomer"
import Loader from "../_shared/Loader/Loader"
import Button from "../_shared/Form/Button/Button"
import Snackbar from "@mui/material/Snackbar"
import {Context} from "../../contexts/Context"
import {CustomerIF} from "../../../types"
import {handleDeleteItem} from "../../shared/_item_helper"

export const customerPath = "/customer/"

const Table = lazy(() => import("./Table"))

interface UpdateCustomerIF {
    input?: CustomerIF
    force?: boolean
}

const Customer = () => {
    const [customer, setCustomer] = useState<CustomerIF>({
        id: 0
    })
    const navigate = useNavigate()
    const urlLocation = useLocation()
    const url = getUrlParams(urlLocation.pathname, ["page", "id"])
    const customerNumber = parseInt(url["id"])
    const title = customer && customer.id ? `Customer #${customer.id}` : "New customer"
    const [loader, setLoader] = useState(true)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const {confirmation} = useContext(Context)

    useEffect(() => {
        if (!customerNumber) {
            localStorage.setItem(lastUrl, `${customerPath}0/`)
            setCustomer({
                id: 0
            })

            setLoader(false)

            return
        }

        getCustomer({
            customerNumber,
            callback: (data: CustomerIF) => {
                setCustomer(data)
                setLoader(false)
            }
        })
    }, [customerNumber])

    const updateCustomer = ({input, force}: UpdateCustomerIF) => {
        const putCustomer = input || customer

        Axios.put(`${endpoint}${customerPath}${putCustomer.id}/`, putCustomer)
            .catch(error => {
                console.error(`So this happened - ${error}`)
            })

        if (force) {
            setSnackbarOpen(true)
        }
    }

    const addCustomer = (force?: boolean) => {
        Axios.post(`${endpoint}${customerPath}`, customer)
            .then(({data}) => {
                const {success, insertId} = data

                if (!success) {
                    console.error(`No luck saving customer ryan, mate! [A]`)
                }

                if (force) {
                    setSnackbarOpen(true)
                }

                navigate(`${customerPath}${insertId}/`)
            })
            .catch(error => console.error(`So this happened - ${error}`))
    }

    const doSave = (force = false) => {
        if (customer.id > 0) {
            updateCustomer({force})
        } else {
            addCustomer(force)
        }
    }

    const onChange = (name: string, value: string) => {
        if (customer.id === 0) {
            return
        }

        const newCustomer = {
            ...customer,
            [name]: value
        }

        setCustomer(newCustomer)
        updateCustomer({input: newCustomer})
    }

    const deleteButton = () => {
        if (!customer || !customer.id) {
            return
        }

        return <Button text="Delete" additionalClassName="large tertiary"
                       onClick={(event: MouseEvent) => handleDeleteItem({
                           event,
                           confirmation,
                           id: customer.id,
                           navigate,
                           text: customerDeleteText,
                           resource: "customer"
                       })}></Button>
    }

    return (
        loader ?
            <Loader/>
            :
            <div>
                <section className="component customer">
                    <header>
                        <h1>{title}</h1>

                        <div className="actions">
                            <Button additionalClassName="primary large" text="Save" onClick={() => doSave(true)}></Button>
                            <Button additionalClassName="secondary large" text="Go back" onClick={() => navigate(customersPath)}></Button>
                            {deleteButton()}
                        </div>
                    </header>

                    <Table customer={customer} onChange={onChange}/>
                </section>

                {customer.id ? <Orders customer={customer.id}/> : ""}

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={1500}
                    onClose={() => setSnackbarOpen(false)}
                    message="Saved..."
                />
            </div>
    )
}

export default Customer
