import React, {useContext, useRef, useState} from "react"
import {endpoint, storageChange, themeString} from "../../variables"
import {Axios} from "../../Axios"
import Button from "../_shared/Form/Button/Button"
import View from "../_shared/Icons/View"
import {AuthContext} from "../../contexts/Auth"
import {handlePasswordSend} from "../../utils/_auth"
import {setAuthentication} from "../Login/Login"
import Snackbar from "@mui/material/Snackbar"
import CreatableSelect from "react-select/creatable"

export const profilePath = "/profile/"

const Profile = () => {
    const authContext = useContext(AuthContext)
    const {rootState: {isAuth, theUser}, setTheUser} = authContext
    const nameRef = useRef<HTMLInputElement>(null)
    const emailRef = useRef<HTMLInputElement>(null)
    const passwordRef = useRef<HTMLInputElement>(null)
    const passwordString = "password"
    const title = "Profile"
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [passwordFieldType, setPasswordFieldType] = useState("password")
    const themes = [
        {label: "Flat (default)", value: "flat"},
        {label: "Color", value: "color"}
    ]
    const themeRef = useRef(null)

    const doSave = (theme?: string) => {
        const currentUsername = nameRef.current?.value || ""
        const currentEmail = emailRef.current?.value || ""
        const currentPassword = passwordRef.current?.value || ""
        const hashPassword = handlePasswordSend(currentPassword)
        // @ts-ignore
        const currentTheme = theme || themeRef.current?.getValue()[0].value

        Axios.defaults.headers.common["Profile"] = window.btoa(`${currentEmail}:${hashPassword}`)

        Axios.put(`${endpoint}/user/`, {
            username: currentUsername,
            email: currentEmail,
            theme: currentTheme
        })
            .then(({data}) => {
                const {success, message} = data

                if (!success) {
                    console.error(message)

                    return
                }

                delete Axios.defaults.headers.common["Profile"]

                setAuthentication({username: currentEmail, password: currentPassword})
                setSnackbarOpen(true)
                setTheUser({
                    ...theUser,
                    name: currentUsername,
                    email: currentEmail,
                    theme: currentTheme
                })
            })
            .catch(error => {
                console.error(`So this happened - ${error}`)
            })
    }

    if (isAuth !== true) {
        return null
    }

    const toggleFieldType = () => {
        const element = passwordRef.current
        const attribute = "type"

        if (!element) {
            return
        }

        const type = element.getAttribute(attribute)
        setPasswordFieldType(type === passwordString ? "text" : passwordString)
    }

    const onChange = (theme: string) => {
        doSave(theme)
        localStorage.setItem(themeString, theme)
        window.dispatchEvent(new Event(storageChange))
    }

    return (
        <section className="component customer">
            <header>
                <h1>{title}</h1>

                <div className="actions">
                    <Button additionalClassName="primary large" text="Save" onClick={() => doSave()}></Button>
                </div>
            </header>

            <form className="general-form profile">
                <div className="field">
                    <label>Name</label>
                    <input
                        ref={nameRef}
                        defaultValue={theUser.name}
                    />
                </div>

                <div className="field">
                    <label>Email</label>
                    <input
                        ref={emailRef}
                        defaultValue={theUser.email}
                    />
                </div>

                <div className="field password-field">
                    <label>Password</label>
                    <input
                        ref={passwordRef}
                        type={passwordFieldType}
                        defaultValue=""
                    />
                    <View callback={toggleFieldType}/>
                    {passwordFieldType !== passwordString &&
                        <svg viewBox="0 0 100 100" className="hollow">
                            <line x1="0" y1="80" x2="100" y2="20" strokeWidth="4" stroke="black"/>
                        </svg>
                    }
                </div>

                <div className="field">
                    <label>Theme</label>
                    <CreatableSelect
                        className="select"
                        classNamePrefix="select"
                        defaultValue={themes.filter(item => item.value === theUser.theme)[0] || themes[0]}
                        name="unit"
                        id="customer-field"
                        options={themes}
                        isValidNewOption={() => false}
                        ref={themeRef}
                        onChange={item => onChange(item?.value as string)}
                    />
                </div>
            </form>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={1500}
                onClose={() => setSnackbarOpen(false)}
                message="Saved..."
            />
        </section>
    )
}

export default Profile
