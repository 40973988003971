import React, {useRef, useState} from "react"
import "./Actions.sass"
import {useOutsideAlerter} from "../../../utils/Hooks/clickOutside"

interface ActionsIF {
    id: number
    compact?: boolean | undefined
    viewCallback?: Function
    deleteCallback: Function
    deleteCallbackParams?: Object
    commentCallback?: () => void
}

const Actions = ({compact, id, viewCallback, deleteCallback, deleteCallbackParams, commentCallback}: ActionsIF) => {
    const [showActions, setShowActions] = useState(false)
    const actionsRef = useRef(null)

    useOutsideAlerter(actionsRef, () => setShowActions(false))

    const handleActionClick = (event: Event) => {
        event.preventDefault()

        setShowActions(!showActions)
    }

    if (compact) {
        return <></>
    }

    return (
        <td ref={actionsRef} className="actions" onClick={(event) => handleActionClick(event as unknown as Event)}>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>

            {showActions &&
                <ul className="action-list">
                    {commentCallback && <li onClick={commentCallback}>Comment</li>}
                    {viewCallback && <li onClick={() => viewCallback(id)}>View</li>}
                    <li onClick={(event) => deleteCallback(event, id, deleteCallbackParams)}>Delete</li>
                </ul>
            }
        </td>
    )
}

export default Actions
