import React, {useState, useEffect, useContext} from "react"
import {lastUrl, productDeleteText} from "../../variables"
import "./Products.sass"
import {useLocation, useNavigate} from "react-router-dom"
import {Context} from "../../contexts/Context"
import {getUrlParams} from "../../utils/_helpers"
import {AuthContext} from "../../contexts/Auth"
import DefaultList from "../_shared/DefaultList/DefaultList"
import {isEmpty} from "../../shared/helpers"
import {productPath} from "../Product/Product"
import {getProducts} from "../../utils/Getters/getProducts"
import NoEntries from "../_shared/NoEntries/NoEntries"
import Actions from "../_shared/Actions/Actions"
import {DataResponseIF, MetaIF, ProductIF} from "../../../types"
import {handleDeleteItem} from "../../shared/_item_helper"

export const productsPath = "/products/"

interface ProductsIF {
    compact?: boolean
}

const Products = ({compact}: ProductsIF) => {
    const [products, setProducts] = useState<Array<ProductIF> | null>(null)
    const [meta, setMeta] = useState<MetaIF | null>(null)
    const urlLocation = useLocation()
    const path = getUrlParams(urlLocation.pathname, ["page", "pageNumber"])
    const pageNumber = path["pageNumber"]
    const page = parseInt(pageNumber) || 1
    const {confirmation} = useContext(Context)
    const {rootState} = useContext(AuthContext)
    const {isAuth} = rootState
    const [loader, setLoader] = useState(true)
    const [unfilteredData, setUnfilteredData] = useState<DataResponseIF | null>(null)
    const [firstRender, setFirstRender] = useState(true)
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (!firstRender) {
            fetchProducts()
        }
    }, [location])

    useEffect(() => {
        localStorage.setItem(lastUrl, `${productsPath}${pageNumber ? `${pageNumber}/` : ""}`)
        setFirstRender(false)
    }, [pageNumber])

    const fetchProducts = () => {
        getProducts({
            callback: (data: any) => {
                setLoader(false)
                setMeta(data.meta)
                setProducts(data.resource)
                setUnfilteredData(data)
            },
            page
        })
    }

    useEffect(() => {
        if (!isAuth) {
            return
        }

        fetchProducts()
    }, [isAuth])

    const handleDelete = (event: Event, id: number, list: Array<ProductIF>) => {
        handleDeleteItem({
                event: event as unknown as Event,
                confirmation,
                id,
                list,
                setList: setProducts,
                navigate,
                text: productDeleteText,
                resource: "product"
            }
        )
    }

    const handleViewClick = (id: number) => {
        navigate(`${productPath}${id}/`)
    }

    const productsTable = () => {
        let productsList = []

        if (!products) {
            return ""
        }

        productsList.push(Object.values(compact ? products.slice(0, 5) : products).map((Product: any, index) => {
            const {id, code, name, price, discountPrice, unit} = Product

            return (
                <tr className="row" key={index}>
                    <td onClick={() => handleViewClick(id)} className="id">{code}</td>
                    <td onClick={() => handleViewClick(id)} className="name">{name}</td>
                    <td onClick={() => handleViewClick(id)} className="items">{price} / {discountPrice}</td>
                    <td onClick={() => handleViewClick(id)} className="items">{unit}</td>
                    <Actions compact={compact} id={id}
                             viewCallback={handleViewClick}
                             deleteCallback={handleDelete}
                             deleteCallbackParams={products}
                    />
                </tr>
            )
        }))

        return productsList
    }

    const showList = () => {
        if (isEmpty(products)) {
            return <NoEntries/>
        }

        return (
            <table className={`general-table products-table${compact ? " compact" : ""}`}>
                <thead>
                <tr>
                    <th className="id">#</th>
                    <th className="name">Name</th>
                    <th className="items">Price</th>
                    <th className="items">Unit</th>
                    {!compact && <th className="actions"/>}
                </tr>
                </thead>
                <tbody>
                {productsTable()}
                </tbody>
            </table>
        )
    }

    const handleCallback = (data: DataResponseIF) => {
        if (!unfilteredData) {
            return
        }

        setMeta(data ? data.meta : unfilteredData.meta)
        // TODO wth happens here? why we need "any"?
        setProducts(data ? data.resource as any : unfilteredData.resource)
    }

    return (
        <DefaultList list="products"
                     loader={loader}
                     setLoader={setLoader}
                     renderer={showList()}
                     meta={meta}
                     page={page}
                     compact={compact}
                     callback={(data: DataResponseIF) => handleCallback(data)}
        />
    )
}

export default Products
