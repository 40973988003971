import React, {lazy, useEffect} from "react"
import {lastUrl} from "../../variables"

const Customers = lazy(() => import("../Customers/Customers"))
const Orders = lazy(() => import("../Orders/Orders"))
const Products = lazy(() => import("../Products/Products"))

export const homePath = "/home/"

const Home = () => {
    useEffect(() => {
        localStorage.setItem(lastUrl, homePath)
    }, [])

    return (
        <div className="dashboard">
            <Orders compact={true}/>
            <Products compact={true}/>
            <Customers compact={true}/>
        </div>
    )
}

export default Home
