import React, {useState, useEffect, useContext, useCallback} from "react"
import {customerDeleteText, endpoint, lastUrl} from "../../variables"
import {Axios} from "../../Axios"
import {useNavigate, useLocation} from "react-router-dom"
import {Context} from "../../contexts/Context"
import {getUrlParams} from "../../utils/_helpers"
import {AuthContext} from "../../contexts/Auth"
import {customerPath} from "../Customer/Customer"
import DefaultList from "../_shared/DefaultList/DefaultList"
import NoEntries from "../_shared/NoEntries/NoEntries"
import Actions from "../_shared/Actions/Actions"
import {CustomerIF, DataResponseIF, MetaIF} from "../../../types"
import {handleDeleteItem} from "../../shared/_item_helper"

export const customersPath = "/customers/"

interface CustomersIF {
    compact?: boolean
}

const Customers = ({compact}: CustomersIF) => {
    const [customers, setCustomers] = useState<Array<CustomerIF> | null>(null)
    const [unfilteredData, setUnfilteredData] = useState<DataResponseIF | null>(null)
    const [meta, setMeta] = useState<MetaIF | null>(null)
    const urlLocation = useLocation()
    const path = getUrlParams(urlLocation.pathname, ["page", "pageNumber"])
    const pageNumber = path["pageNumber"]
    const page = parseInt(pageNumber) || 1
    const navigate = useNavigate()
    const {confirmation} = useContext(Context)
    const {rootState} = useContext(AuthContext)
    const {isAuth} = rootState
    const [loader, setLoader] = useState(true)

    const fetchCustomers = useCallback(() => {
        Axios.get(`${endpoint}${customersPath}${page}/${compact ? "id/" : ""}`)
            .then(({data}) => {
                setLoader(false)
                setMeta(data.meta)
                setCustomers(data.resource)
                setUnfilteredData(data)
            })
            .catch(error => {
                console.error(`So this happened - ${error}`)
            })
    }, [page])

    useEffect(() => {
        localStorage.setItem(lastUrl, `${customersPath}${pageNumber ? `${pageNumber}/` : ""}`)
    }, [pageNumber])

    useEffect(() => {
        if (!isAuth) {
            return
        }

        fetchCustomers()
    }, [isAuth, fetchCustomers])

    const handleDelete = (event: Event, id: number, list: Array<CustomerIF>) => {
        handleDeleteItem({
            event: event as unknown as Event,
            confirmation,
            id,
            list,
            setList: setCustomers,
            navigate,
            text: customerDeleteText,
            resource: "customer"
        })
    }

    const customersTable = () => {
        let customersList = []

        if (!customers) {
            return ""
        }

        const handleViewClick = (id: number) => {
            navigate(`${customerPath}${id}/`)
        }

        customersList.push(Object.values(compact ? customers.slice(0, 5) : customers).map((customer: any, index) => {
            const {id, name, nickname, amount_of_orders, total_offer} = customer

            return (
                <tr className="row" key={index}>
                    <td onClick={() => handleViewClick(id)} className="id">{id}</td>
                    <td onClick={() => handleViewClick(id)} className="name">{name}{nickname && ` (${nickname})`}</td>
                    <td onClick={() => handleViewClick(id)} className="items">{amount_of_orders}</td>
                    <td onClick={() => handleViewClick(id)} className="items">{total_offer}€</td>

                    <Actions compact={compact} id={id}
                             viewCallback={handleViewClick}
                             deleteCallback={handleDelete}
                             deleteCallbackParams={customers}
                    />
                </tr>
            )
        }))

        return customersList
    }

    const showList = () => {
        if (!customers?.length) {
            return <NoEntries/>
        }

        return (
            <>
                <table className={`general-table customers-table${compact ? " compact" : ""}`}>
                    <thead>
                    <tr>
                        <th className="id">#</th>
                        <th className="name">Name</th>
                        <th className="items">Orders</th>
                        <th className="items">Total</th>
                        {!compact && <th className="actions"/>}
                    </tr>
                    </thead>
                    <tbody>
                    {customersTable()}
                    </tbody>
                </table>
            </>
        )
    }

    const handleCallback = (data: DataResponseIF) => {
        if (!unfilteredData) {
            return
        }

        setMeta(data ? data.meta : unfilteredData.meta)
        setCustomers(data ? data.resource : unfilteredData.resource)
    }

    return (
        <DefaultList list="customers"
                     loader={loader}
                     setLoader={setLoader}
                     renderer={showList()}
                     meta={meta}
                     page={page}
                     compact={compact}
                     callback={(data: DataResponseIF) => handleCallback(data)}
        />
    )
}

export default Customers
